import React, { useState, useEffect } from "react";
// import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Layout from "@components/layout"
import LazyLoad from 'react-lazy-load';

// Import images
import eye_catch from "@images/eye_catch.png" // アイキャッチ画像元データ
import Rogo from "@images/rogo.svg"

// 言語用Jsonファイル
import langEn from "@content/locales/en/blog-post.json";
import langJa from "@content/locales/ja/blog-post.json";

// コンポーネント
// import { LinkSetting } from "@components/link-setting.js"; // 共通Link用ファイル
import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定
import { ChangeContents } from "@components/change-contents.js"; // 本文HTML操作
import { GraphqlBannerArray } from "@components/graphQL-bannerData.js" // banner設定
import { GetLeadresBoardData } from "@components/api-getLeadresBoardData.js";
import { useLocation } from "@reach/router"
import { SetCookie } from "@components/setCookie"; // クッキー処理 ※IBリンクを発行してるページに設置

const CampaignPost = ( data ) => {

  // 登録フォーム設定-クッキー処理
  const location = useLocation()
  let cookieArray = SetCookie( location )
  let lid, pid;
  if ( cookieArray !== undefined ){
    lid = cookieArray.lidID;
    pid = cookieArray.pidID;
  }
  const [count, setCount] = useState(0); // 再レンダリング処理
  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
    }, 1); // 1/1000秒後
  }, []);

  const postData = data.pageContext;

  // 言語別設定
  let postLang, langUrl
  const setLang = postData.lang;
  setLang === 'en'? postLang = langEn: postLang = langJa;
  setLang === 'en'? langUrl = '/': langUrl = '/' + setLang + '/';

  // 本文記事
  const postContent = ChangeContents(postData.data.node.content, setLang);

  // ブログ別の翻訳変数設定
  let title, txt1, txt2 ; 
  title = postLang[`${postData.category}-page-title`];
  txt1 = postLang[`${postData.category}-page-txt1`];
  txt2 = postLang[`${postData.category}-page-txt2`];
  
  // リンク設定
  // const signPath1 = LinkSetting( setLang ).Register;

  // アイキャッチ画像設定
  // const date = postData.data.node.date;
  // const featuredImage = {
  //   data: postData.data.node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  //   alt: postData.data.node.featuredImage?.node?.alt || ``,
  // }
  // let eyecatchData, eyecatchType;
  // eyecatchType =  marketsEyecatch(date)
  // eyecatchData = postData.data.node.featuredImage? 
  //   // wordpressで設定あり（手動設定）
  //   <>
  //     {featuredImage?.data && (<GatsbyImage image={featuredImage.data} alt={featuredImage.alt}/>)}
  //   </> : 
  //   // wordpressで設定なし（自動出力）
  //   eyecatchType ;

  // カテゴリー画像情報取得
  const [pageBannerData] = useState(() => GraphqlBannerArray("campaign-category-image", setLang));
  let categoryImage;
  if(postData.category === "depositcampaign") {
    // 100%bonus
    if (postData.data.node.bonusTag?.nodes[0]?.slug === "100percent-depositbonus") {
      categoryImage = pageBannerData.find(result => result.class === "depositcampaign");
    }
    // 30%bonus
    if (postData.data.node.bonusTag?.nodes[0]?.slug === "30percent-depositbonus") {
      categoryImage = pageBannerData.find(result => result.class === "depositcampaign30");
    }
  } else {
    categoryImage = pageBannerData.find(result => result.class === postData.category);
  }

  // reader board設定
  const num = 5; // 表示するデータ数
  const boardArray = GetLeadresBoardData(num);
  const [pageNum, setPage] = useState(1);
  const handleClickIncrease = () => {setPage(pageNum + 1);};
  const handleClickDecrease = () => {setPage(pageNum - 1);};

  // 構造化データ用配列
  const collection = {image: `${process.env.ORIGINURL}${postData.data.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src}`};
  // 作成・更新日
  const dateArray = {
    published: new Date(postData.data.node.date).toISOString(), 
    modified: new Date(postData.data.node.modified).toISOString()
  };
  
  // Seo情報設定
  const seoTag = SeoContents(
    postData.uri, setLang,
    postData.data.node.title.replace(/<br>/g, ''),
    postData.seo.metaDesc !== ''? postData.seo.metaDesc: parse(postData.data.node.excerpt)[0].props.children[0],
    postLang[`${postData.category}-page-keywords`],
    dateArray, 'campaign', postData.slug, postData.category, collection
  );

  return (
  <>
    {seoTag}
    <Layout>
      <article className="blog-post" itemScope itemType="http://schema.org/Article" langType={setLang}>
        
        {/* カテゴリー画像 */}
        <div className="relative">          
          <div className='hidden md:block'>
            <LazyLoad className='aspect-[35/10]' offset={300}>
              <img className="w-full" alt={categoryImage.name} src={categoryImage.pcimage}/>
            </LazyLoad>
            {parse(categoryImage.pctext)}
          </div>
          <div className='md:hidden'>
            <LazyLoad className='aspect-[15/20]' offset={300}>
              <img className="w-full" alt={categoryImage.name} src={categoryImage.mbimage}/>
            </LazyLoad>
            {parse(categoryImage.mbtext)}
          </div>
        </div>
        
        {/* <div className="FV pt-5 pb-8 bg-fixed">
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
            <div className=" text-left h1_filed">
              <h1 className="md:text-[60px] text-[35px] font-semibold">{title}aaaa</h1>
              {/* <div className="text-base max-w-[680px]">
                <p>{txt1}{txt2}</p>
              </div> 
            </div>
          </div>
        </div> */}
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl mb-[-30px] flex py-5 text-[15px] ">
        <span className="text-[14px] leading-3 !text-gray-700">キャンペーン ＞ </span><h1 className="pl-2 text-[14px] leading-3 !text-gray-700">{title}</h1>
        </div>
        <div className="md:px-0 px-6">
          <div className=" md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
            {!!postData.data.node.content && (
              <section itemProp="articleBody">{postContent}</section>
            )}
          </div>
        </div>
      </article>

      {/* Leader board */}
      {postData.category === 'tradecampaign'?
        <div className={`max-w-xl mx-auto md:pt-0 pt-6 md:max-w-5xl px-6`}>
          <div className="flex mx-auto max-w-[800px] justify-center items-center">
            <p className="border-b-[1px] md:w-[100px] w-[40px] md:pt-10 pt-5 mx-4 border-b-[#707070]"></p>
            <h2 className="mb-2 text-center pt-[5%] text-[22px] md:text-[28px]">{postLang[`tradecampaign-txt4`]}</h2>
            <p className="border-b-[1px] md:w-[100px] w-[40px] md:pt-10 pt-5 mx-4 border-b-[#707070]"></p>
          </div>
          <div className="bg-[#FAFAFA] w-full pb-[2px] mt-[2%] md:mb-0 mb-[10%] rounded-md">
            <p className="mx-10  pt-10"><img className="w-[90px] " src={ Rogo } alt=""></img></p>
              <h3 className="mx-0 mt-2 pb-5 border-b-[1px] border-[#DDDDDD] px-10 pb-5 font-semibold text-2xl">Leader board</h3>
              {boardArray !== null && boardArray.length !== 0?
              <>
                <table className="mx-[5%] w-[90%] md:text-base text-[13px]">
                  <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                    <td className="py-5 md:w-[25%] w-[40%]">{postLang[`tradecampaign-txt5`]}</td>
                    <td className="md:w-[18%] w-[23%]">{postLang[`tradecampaign-txt6`]}</td>
                    <td className="">{postLang[`tradecampaign-txt7`]}</td>
                  </tr>
                  {boardArray[pageNum - 1].map((result) =>
                    <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] ">
                      <td className="py-5 h-[65px]">{result.nickname}</td><td>{result.country}</td><td>{result.performance}</td>
                    </tr>
                  )}  
                </table>
                <div className="flex justify-end mx-[5%] w-[90%] md:text-base text-[13px] pb-6">1 - {boardArray.length} of {pageNum}
                  <div className='ml-[16px] font-bold'>
                    {pageNum === 1? <span className='text-[rgb(129_129_129)]'>＜</span>:<button className='' onClick={handleClickDecrease}>＜</button>}
                  </div>
                  <div className='ml-[16px] font-bold'>
                    {pageNum === boardArray.length? <span className='text-[rgb(129_129_129)]'>＞</span>:<button className='' onClick={handleClickIncrease}>＞</button>}
                  </div>
                </div>
              </>
              :
              
              <>
                <table className="mx-[5%] w-[90%] md:text-base text-[13px]">
                  <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                    <td className="py-5 md:w-[25%] w-[40%]">ニックネーム</td><td className="md:w-[18%] w-[23%]">国</td><td className="">パフォーマンス</td>
                  </tr>
                  <tr className="mx-10 mt-10  border-b-[1px] border-[#DDDDDD] text-[#818181]">
                  {boardArray === null?
                    <td className="py-5">Loading...</td>
                    :
                    <td className="py-5">no data</td>
                  }
                  </tr>
                </table>
              </>
              }
          </div>
        </div>
        :
        <></>
      }
      
      {/* <div className="Feature pt-5 mt-10 bg-fixed">
        <div className="flex px-5 text-[11px] md:text-sm">
          <nav className="blog-post-nav w-full my-0 mx-auto" style={{ maxWidth: 976 }}>
            <ul style={{ display: `flex`, flexWrap: `wrap`, justifyContent: 'center', alignItems: 'center', listStyle: `none`, padding: 0 }}>
              <li className="mb-0 text-left" style={{ width: '33%' }}>
                {postData.previousSlug?
                  <a href={`${langUrl}${postData.uri}/${postData.previousSlug}/`} className='' rel="prev">＜ {postLang["post-previousPage"]}</a>
                  :
                  <></>
                }
              </li>
              <li className="mb-0 text-center" style={{ width: '33%' }}>
                <a href={`${langUrl}${postData.uri}/${postData.pagenum}/`} className="">{postLang["post-archivePage"]}</a>
              </li>
              <li className="mb-0 text-right" style={{ width: '33%', textAlign: 'right' }}>
                {postData.nextSlug?
                  <a href={`${langUrl}${postData.uri}/${postData.nextSlug}/`} className='' rel="next">{postLang["post-nextPage"]} ＞</a>
                  :
                  <></>
                }
              </li>
            </ul>
          </nav>
        </div>

        <div className="mt-5 py-14 py-14  md:py-10 md:py-10">
          <div className="md:mx-auto max-w-5xl flex justify-center">
            <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 ">{postLang["open"]}</a>
          </div> 
        </div>
      </div> */}
    </Layout>
  </>
  )
}
export default CampaignPost

// アイキャッチ生成
function marketsEyecatch(date) {
	let setHtml =
	<>
		<div className={"w-full bg-center bg-cover h-[55%]"} 
		style={{
			backgroundImage: `url(${eye_catch})`,
			aspectRatio: "25/18.5",
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat'
			}}>
			<div className="w-full h-full flex justify-center items-center">
				<div className="p-3 text-center ">
					<p style={{fontSize : 32}} className={"text-[32px] tracking-widest"}>{date}</p>
					<p style={{fontSize : 24}} className={"text-[24px] tracking-widest text-[red]"}>Markets Analysis</p>
				</div>                
			</div>
		</div>
  </> ;
	return setHtml
}